import { useLayoutEffect, useState } from "react";
import { Router } from "react-router-dom";
import { BrowserHistory } from "history";

interface BrowserRouterProps {
  basename?: string;
  children?: React.ReactNode;
  history: BrowserHistory;
}

const BrowserRouter = (props: BrowserRouterProps) => {
  const [state, setState] = useState({
    action: props.history.action,
    location: props.history.location
  });

  useLayoutEffect(() => props.history.listen(setState), [props.history]);

  return <Router basename={props.basename} children={props.children} location={state.location} navigationType={state.action} navigator={props.history} />;
};

export default BrowserRouter;
